<template>
  <div>
    <div
      class="item_card"
      v-for="(item, itemIndex) in gameData"
      :key="itemIndex"
    >
      <div :style="{ backgroundColor: themeColor }" class="question_number">
        {{ itemIndex + 1 }}
      </div>
      <div v-if="item.title" class="question_title">{{ item.title }}</div>
      <template v-for="(itemImg, imgIndex) in item.items">
        <preview-img
          v-if="itemImg.option_text"
          :src="itemImg.option_text"
          :key="imgIndex"
        ></preview-img>
      </template>
    </div>
  </div>
</template>

<script>
import globalConfig from '@/store/global_config';
import { PUBLIC_DATA } from '@/utils/common';
import PreviewImg from '@/components/PreviewImg';
const { indexPrefix, imgUrl } = PUBLIC_DATA;
export default {
  props: {
    itemData: {
      default: () => {
        [];
      },
      type: Object
    }
  },

  data() {
    return {
      indexPrefix,
      imgUrl
    };
  },
  components: { PreviewImg },

  methods: {},

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    gameData() {
      const newArr = [];
      this.itemData.questions.forEach(item => {
        newArr.push(item);
      });
      return newArr;
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
