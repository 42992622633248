<template>
  <div>
    <div
      class="item_card"
      v-for="(item, itemIndex) in itemData.questions"
      :key="itemIndex"
    >
      <div :style="{ backgroundColor: themeColor }" class="question_number">
        {{ itemIndex + 1 }}
      </div>
      <div v-if="item.title" class="question_title">{{ item.title }}</div>
      <div>
        你的答案:
        <span
          :style="{ color: item.isRight ? themeColor : 'red' }"
          class="user_answer"
        >
          {{ item.used ? item.used.user_answer : '' }}
        </span>
      </div>
      <div class="dashed_line">
        正确答案：
        <span :style="{ color: themeColor }">{{ item.answer }}</span>
      </div>
      <div
        class="right_analysis dashed_line"
        v-if="item.analysis || item.analysis_img_url"
      >
        答案解析：
      </div>
      <div class="analysis">{{ item.analysis }}</div>
      <preview-img
        v-if="item.analysis_img_url"
        :src="item.analysis_img_url"
      ></preview-img>
    </div>
  </div>
</template>

<script>
import globalConfig from '@/store/global_config';
import { PUBLIC_DATA } from '@/utils/common';
import PreviewImg from '@/components/PreviewImg';

const { imgUrl } = PUBLIC_DATA;
export default {
  components: { PreviewImg },
  props: {
    itemData: {
      default: () => {
        [];
      },
      type: Object
    }
  },
  data() {
    return {
      imgUrl
    };
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    }
  },

  methods: {
    showImg() {}
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
.user_answer {
  padding-left: 20px;
}
</style>
