<template>
  <div>
    <div
      class="reading_content"
      :style="{ fontSize: fontSize + 'rpx' }"
      v-for="(item, itemIndex) in itemArr"
      :key="itemIndex"
    >
      <div v-html="item"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      default: '',
      type: String
    },
    fontSize: {
      default: '34',
      type: String
    }
  },
  computed: {
    itemArr() {
      if (!this.text) {
        return [];
      }
      const t = this.text.split(/\n/);
      const newArr = [];
      t.forEach(item => {
        newArr.push(item);
      });
      return newArr;
    }
  }
};
</script>

<style lang="less" scoped>
.reading_content {
  text-indent: 2em;
  text-align: left;
  font-size: 16px;
}
</style>
