<template>
  <a-breadcrumb class="my_bread">
    <a-breadcrumb-item v-for="item in breadMenu" :key="item.path">
      <router-link v-if="item.path || item.isBack" :to="item.path || ''">
        <span v-if="item.isBack" @click="toBack">{{ item.title }}</span>
        <span v-else>{{ item.title }}</span>
      </router-link>
      <span v-else>
        {{ item.title }}
      </span>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    breadMenu: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    toPath(item) {
      console.log(item);
    },

    toBack() {
      this.$router.back(-1);
    }
  }
};
</script>

<style lang="less" scoped>
.my_bread {
  height: 50px;
  line-height: 50px;
}
</style>
