<template>
  <div class="outer_container">
    <MyBreadCrumb :breadMenu="breadMenu"></MyBreadCrumb>
    <div class="inner_content_container" @scroll="outerScroll">
      <div class="reading_content">
        <reading-info :info="readingInfo"></reading-info>
      </div>
      <div
        class="nav_scroll my_scroll"
        :class="{ fixed_title: titleFixedStatus }"
      >
        <template v-for="(navItem, navIndex) in questionList">
          <span
            :style="{
              color: template == navItem.template ? themeColor : 'inherit'
            }"
            class="nav_title"
            @click="chooseTemplate(navItem, navIndex)"
            :key="navItem.id"
          >
            {{ navItem.title }}
          </span>
        </template>
      </div>
      <div class="empty_nav" v-if="titleFixedStatus"></div>
      <template v-for="(item, itemKey) in questionList">
        <template
          v-if="item.template === template && item.gameId == templateId"
        >
          <topic-reading
            :key="itemKey"
            :itemData="item"
            v-if="template === 'topic-reading'"
          ></topic-reading>
          <cloze-test
            :key="itemKey"
            :itemData="item"
            v-if="template === 'cloze-test'"
          ></cloze-test>
          <topic-writing
            :key="itemKey"
            :itemData="item"
            v-if="template === 'topic-writing'"
          ></topic-writing>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import MyBreadCrumb from '../../components/BreadCrumb';
import { fetchReadingReportDetail } from '../../api/service';
import globalConfig from '@/store/global_config';
import { showMyHint, PUBLIC_DATA } from '../../utils/common';
import ReadingInfo from '../../components/ReadingInfo';
import TopicReading from './components/ReportDetail/Reading/TopicReading';
import TopicWriting from './components/ReportDetail/Reading/TopicWriting';
import ClozeTest from './components/ReportDetail/Reading/ClozeTest';
const { imgUrl } = PUBLIC_DATA;
export default {
  components: {
    MyBreadCrumb,
    ReadingInfo,
    TopicReading,
    TopicWriting,
    ClozeTest
  },
  data() {
    return {
      imgUrl,
      questionList: [],
      template: '',
      templateId: '',
      titleFixedStatus: false,
      scrollId: 'id_0',
      readingInfo: {},
      modalImg: '',
      modalVisible: false,
      breadMenu: []
    };
  },

  beforeDestroy() {
    globalConfig.commit('stopAudio');
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    }
  },

  mounted() {
    const { bookId, chapterId, typeId, pId } = this.$route.params;
    this.bookId = bookId;
    this.chapterId = chapterId;
    this.typeId = typeId;
    this.pId = pId;
    this.fetchReadingDetail();
    this.breadMenu = [
      {
        title: '选择教材',
        path: '/book'
      },
      {
        title: '教材详情',
        path: `/book/${this.bookId}`
      },
      {
        title: '学习报告',
        isBack: true
      },
      {
        title: '报告详情'
      }
    ];
  },

  methods: {
    outerScroll(event) {
      const scrollTop = event.target.scrollTop;
      if (scrollTop > 400) {
        this.titleFixedStatus = true;
      } else {
        this.titleFixedStatus = false;
      }
    },

    hideLoading() {
      this.$loading.hide();
    },

    fetchReadingDetail() {
      fetchReadingReportDetail({
        book_id: this.bookId,
        book_chapter_id: this.chapterId,
        type_id: this.typeId,
        practice_count_id: this.pId
      }).then(response => {
        const { game, reading } = response.data;
        if (!game.length) {
          showMyHint('暂无数据', () => {
            window.history.back();
          });
          return;
        }
        this.readingInfo = reading;
        this.questionList = game;
        const { gameId, template } = game[0] || {};
        this.template = template;
        this.templateId = Number(gameId);
      });
    },

    chooseTemplate(item) {
      const { gameId, template } = item;
      if (this.template.trim() === template && this.templateId === gameId) {
        return;
      }
      this.template = template.trim();
      this.templateId = Number(gameId);
    }
  }
};
</script>

<style lang="less" scoped>
.outer_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .inner_content_container {
    flex: 2;
    position: relative;
    overflow-y: scroll;
    .reading_content {
      max-height: 400px;
      overflow-y: scroll;
    }
  }
  .nav_scroll {
    top: 0;
    z-index: 100;
    white-space: nowrap;
    box-sizing: border-box;
    background: white;
    border-top: solid 2px #f5f5f5;
    height: 50px;
    line-height: 50px;
    width: 100%;
    overflow-x: scroll;
    &.fixed_title {
      position: fixed;
      top: 136px;
      left: 5%;
      width: 90%;
      border-bottom: solid 1px #f5f5f5;
    }
    .nav_title {
      display: inline-template;
      text-align: center;
      padding: 0 20px;
    }
  }
  .empty_nav {
    height: 50px;
  }
}
.content_box {
  padding-top: 70px;
}
</style>
