<template>
  <a-card class="article-container">
    <div :style="{ color: themeColor }" class="tips" v-if="info.tips">
      {{ info.tips }}
    </div>
    <my-text-area :text="info.en_content"></my-text-area>
    <preview-img v-if="info.img_url" :src="info.img_url"></preview-img>
  </a-card>
</template>

<script>
import MyTextArea from '@/components/MyTextArea';
import globalConfig from '@/store/global_config';
import PreviewImg from '@/components/PreviewImg';
import { PUBLIC_DATA } from '@/utils/common';
const { imgUrl } = PUBLIC_DATA;
export default {
  components: { MyTextArea, PreviewImg },
  data() {
    return {
      imgUrl
    };
  },
  props: {
    info: {
      default: () => {},
      type: Object
    }
  },
  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    }
  }
};
</script>

<style lang="less" scoped>
.img-box {
  text-align: center;
}
</style>
