<template>
  <div>
    <div
      class="item_card"
      v-for="(item, itemIndex) in gameData"
      :key="itemIndex"
    >
      <div :style="{ backgroundColor: themeColor }" class="question_number">
        {{ itemIndex + 1 }}
      </div>
      <div v-if="item.title" class="question_title">{{ item.title }}</div>
      <div v-if="item.items[0].type === 'image'">
        <a-radio-group class="radio_group">
          <label
            class="label_item flex"
            v-for="(itemImg, imgIndex) in item.items"
            :key="imgIndex"
          >
            <template
              v-if="itemImg.id == item.answer || itemImg.id == item.userAns"
            >
              <span
                v-if="itemImg.id == item.answer"
                :style="{ color: themeColor }"
                class="icon_img anticon-duihao anticon-yyashow"
              ></span>
              <span
                v-else
                class="anticon-cuowu anticon-yyashow"
                mode="widthFix"
              ></span>
            </template>
            <a-radio
              v-if="itemImg.id != item.answer && itemImg.id != item.userAns"
              class="label_raido"
              disabled
              :value="itemImg.id"
            ></a-radio>
            <span class="index_add">{{ indexPrefix[imgIndex] }}.</span>
            <img
              mode="widthFix"
              class="radio_img"
              v-if="imgUrl"
              :src="imgUrl + itemImg.option_text"
              alt
            />
          </label>
        </a-radio-group>
      </div>
      <div v-else>
        <new-play-radio
          :hasConfirm="true"
          :chooseId="item.used ? item.used.user_answer : ''"
          :answer="item.answer"
          :items="item.items"
        ></new-play-radio>
      </div>
      <div class="your_answer dashed_line">
        你的答案:
        <span :style="{ color: item.isRight ? themeColor : 'red' }">
          {{ item.userAnsIndex }}
        </span>
      </div>
      <div class="right_anwer dashed_line">
        正确答案:
        <span :style="{ color: themeColor }">{{ item.rightIndex }}</span>
      </div>
      <div
        class="right_analysis dashed_line"
        v-if="item.analysis || item.analysis_img_url"
      >
        答案解析：
      </div>
      <div class="analysis">{{ item.analysis }}</div>
      <preview-img
        v-if="item.analysis_img_url"
        :src="item.analysis_img_url"
      ></preview-img>
    </div>
  </div>
</template>

<script>
import globalConfig from '@/store/global_config';
import NewPlayRadio from '@/components/PlayRadio';
import { PUBLIC_DATA } from '../../../../../utils/common';
import PreviewImg from '@/components/PreviewImg';
const { indexPrefix, imgUrl } = PUBLIC_DATA;
export default {
  props: {
    itemData: {
      default: () => {
        [];
      },
      type: Object
    }
  },

  data() {
    return {
      indexPrefix,
      imgUrl
    };
  },
  components: { NewPlayRadio, PreviewImg },

  methods: {
    showImg() {}
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    gameData() {
      const newArr = [];
      this.itemData.questions.forEach(item => {
        const { used, answer, items } = item;
        const { user_answer: userAns } = used || {};
        let answerIndex = '';
        let userIndex = '';
        items.forEach((item, itemIndex) => {
          if (Number(item.id) === Number(answer)) {
            answerIndex = itemIndex;
          }
          if (Number(item.id) === Number(userAns)) {
            userIndex = itemIndex;
          }
        });
        newArr.push({
          ...item,
          userAns: userAns,
          userAnsIndex: indexPrefix[userIndex],
          rightIndex: indexPrefix[answerIndex]
        });
      });
      return newArr;
    }
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
@import './index.less';
.radio_group {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .label_item {
    margin-bottom: 20px;
    width: 50%;
    .anticon-yyashow {
      margin-right: 20px;
    }
    &.flex {
      display: flex;
    }
    .radio_img {
      width: 100px;
      margin-left: 20px;
    }
  }
}
</style>
