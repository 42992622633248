<template>
  <div class="preview_img_box" v-if="src">
    <img preview preview-text="" :src="imgSrc" class="preview_img" alt />
    <div class="preview_btn">点击查看大图</div>
  </div>
</template>

<script>
import { handleWithUrl } from '@/utils/common';
export default {
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  computed: {
    imgSrc() {
      return handleWithUrl(this.src);
    }
  }
};
</script>

<style lang="less" scoped>
.preview_img_box {
  text-align: left;
  position: relative;
  display: inline-block;
  .preview_img {
    width: 200px;
  }
  .preview_btn {
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 0rpx 20rpx;
    font-size: 28rpx;
  }
}
</style>
