<template>
  <a-radio-group :value="chooseId" class="radio_outer" @change="radioChange">
    <label
      v-for="(item, index) in items"
      :key="index"
      class="radio_item"
      :style="{
        color: !hasConfirm
          ? 'inherit'
          : item.id != chooseId && item.id != answer
          ? 'inherit'
          : !isChooseRightAnswer && item.id == chooseId
          ? 'red'
          : themeColor
      }"
    >
      <span
        v-if="
          hasConfirm && imgUrl && !isChooseRightAnswer && chooseId == item.id
        "
        class="anticon-cuowu anticon-yyashow icon_img"
        mode="widthFix"
      ></span>
      <span
        v-if="hasConfirm && imgUrl && answer == item.id"
        :style="{ color: themeColor }"
        class="icon_img anticon-duihao anticon-yyashow"
      ></span>
      <a-radio
        :value="item.id"
        v-if="
          !hasConfirm ||
            (isChooseRightAnswer && item.id != answer) ||
            (!isChooseRightAnswer && chooseId != item.id && answer != item.id)
        "
        :disabled="hasConfirm"
        :color="themeColor"
      />
      <span class="item_index">{{ indexObj[index] }}.</span>
      <span class="item_index">{{ item.option_text }}</span>
    </label>
  </a-radio-group>
</template>

<script>
import globalConfig from '@/store/global_config';
import { PUBLIC_DATA } from '../../utils/common';
const { imgUrl } = PUBLIC_DATA;
export default {
  methods: {
    radioChange(e) {
      this.$emit('radioChange', e.target.value, this.hisId);
    }
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    isChooseRightAnswer() {
      return (
        this.chooseId &&
        this.answer &&
        Number(this.chooseId) === Number(this.answer)
      );
    }
  },

  props: {
    answer: {
      type: [Number, String],
      default: ''
    },
    chooseId: {
      type: [Number, String],
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    hasConfirm: {
      type: Boolean,
      default: false
    },
    hisId: {
      type: [String, Number],
      default: ''
    }
  },

  data() {
    return {
      imgUrl: imgUrl,
      indexObj: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'U',
        'V'
      ]
    };
  },

  mounted() {}
};
</script>

<style lang="less" scoped>
.ant-radio-group {
  display: block;
  width: 100%;
  .ant-radio-wrapper {
    font-size: 16px;
  }
}

.radio_outer {
  text-align: left;
  padding-left: 20px;
  .radio_item {
    display: block;
    margin-top: 10px;
    .item_index {
      margin-left: 20px;
      margin-right: 15px;
      vertical-align: middle;
      font-size: 16px;
    }
    .icon_img {
      width: 26px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
