var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-radio-group',{staticClass:"radio_outer",attrs:{"value":_vm.chooseId},on:{"change":_vm.radioChange}},_vm._l((_vm.items),function(item,index){return _c('label',{key:index,staticClass:"radio_item",style:({
      color: !_vm.hasConfirm
        ? 'inherit'
        : item.id != _vm.chooseId && item.id != _vm.answer
        ? 'inherit'
        : !_vm.isChooseRightAnswer && item.id == _vm.chooseId
        ? 'red'
        : _vm.themeColor
    })},[(
        _vm.hasConfirm && _vm.imgUrl && !_vm.isChooseRightAnswer && _vm.chooseId == item.id
      )?_c('span',{staticClass:"anticon-cuowu anticon-yyashow icon_img",attrs:{"mode":"widthFix"}}):_vm._e(),(_vm.hasConfirm && _vm.imgUrl && _vm.answer == item.id)?_c('span',{staticClass:"icon_img anticon-duihao anticon-yyashow",style:({ color: _vm.themeColor })}):_vm._e(),(
        !_vm.hasConfirm ||
          (_vm.isChooseRightAnswer && item.id != _vm.answer) ||
          (!_vm.isChooseRightAnswer && _vm.chooseId != item.id && _vm.answer != item.id)
      )?_c('a-radio',{attrs:{"value":item.id,"disabled":_vm.hasConfirm,"color":_vm.themeColor}}):_vm._e(),_c('span',{staticClass:"item_index"},[_vm._v(_vm._s(_vm.indexObj[index])+".")]),_c('span',{staticClass:"item_index"},[_vm._v(_vm._s(item.option_text))])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }